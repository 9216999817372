<template>
  <div class="common-layout">
      <!-- <el-container>
        <el-aside width="140px"></el-aside>
        <el-main> -->
          <router-view></router-view>
        <!-- </el-main>
      </el-container> -->
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  height: calc(100vh);
  width: calc(100vw);
  color: #111111;
}
body{
  margin: 0;
  
}
.common-layout{
  .el-header{
    padding: 0;
    background: rgb(198, 226, 255);
  }
  .el-aside{
    background-color:rgb(217, 236, 255);
  }
  .el-main{
    height: calc(100vh);
  }
}

</style>
