import { createRouter, createWebHashHistory } from "vue-router";


const home = () => import("../home.vue");
const login = () => import("../login.vue");
const phone = () => import("../phone.vue");

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/",
            component: home
        },
        {
            path: "/login",
            component: login
        },
        {
            path: "/phone",
            component: phone
        }
    ]
})

export default router;